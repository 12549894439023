<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<script>
import iframeMixin from '../iframeMixin.vue'

export default {
  mixins: [iframeMixin],
  computed: {
    url() {
     return '/ureport/preview?_u=mysql:tickUpdateReport.ureport.xml&_i=1'
    },
  },
}
</script>
